import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { useEffect, useState } from "react";
import { storageUrl } from "./firebase";

const ImageCarousel = ({
  pictures,
  totalPictures,
}: {
  pictures: string[];
  totalPictures: number;
}) => {
  // const [aspect, setAspect] = useState([0, 0]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (pictures.length > 0) {
      const img = new Image();
      img.onload = () => {
        // setAspect([img.width, img.height]);
        setLoaded(true);
      };

      img.src = pictures[0];
    }
  }, [pictures]);

  if (!loaded) {
    return <> </>;
  }
  // height:inherit;
  return (
    <CarouselProvider
      naturalSlideWidth={150}
      naturalSlideHeight={100}
      totalSlides={totalPictures}
      className="relative h-full mt-5"
    >
      <Slider>
        {pictures.map((photo, index) => (
          <Slide key={index} index={index} className="text-center">
            <img
              className="apartmentimg inline"
              alt="apartmentpicture"
              src={storageUrl(photo)}
            />
          </Slide>
        ))}
      </Slider>
      {pictures.length === 1 || (
        <>
          <ButtonBack className="absolute top-1/2 left-10 transform -translate-x-1/2 -translate-y-1/2 text-4xl font-bold ">
            {`<`}
          </ButtonBack>
          <ButtonNext className="absolute top-1/2 right-5 transform -translate-x-1/2 -translate-y-1/2 text-4xl font-bold">
            {`>`}
          </ButtonNext>
        </>
      )}
    </CarouselProvider>
  );
};

export default ImageCarousel;
