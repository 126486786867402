import { useParams } from "react-router-dom";
import useSite from "./useSite";
import { useApartments } from "./useApartments";
import BuildingViewCarousel from "./BuildingViewCarousel";
import ApartmentTable from "./ApartmentTable";
import { apartmentFilter } from "./recoil";
import { useRecoilValue } from "recoil";

const Planner = () => {
  const { company, sitename } = useParams();

  if (!company) {
    throw new Error("no company provided to details");
  }

  if (!sitename) {
    throw new Error("no site provided to details");
  }

  const [site] = useSite(company, sitename);
  const siteId = site?.siteid;
  const overviewPicture = site?.overviewpicture;

  useApartments(company, siteId);
  const filteredApartments = useRecoilValue(apartmentFilter);

  if (!siteId) {
    // captureMessage(
    // "Site does not exist:" + company + "/" + sitename,
    // "warning",
    // );
    return <></>;
  }

  return (
    <div className="planner-wrapper relative">
      <BuildingViewCarousel
        company={company}
        siteId={siteId}
        overviewPicture={overviewPicture}
      />
      <ApartmentTable apartments={filteredApartments} />
    </div>
  );
};

export default Planner;
