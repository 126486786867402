import { createElement, useCallback, useEffect, useState } from "react";
import { storageUrl } from "./firebase";

interface TArg {
  content: string | null;
  activeAnchor: HTMLElement | null;
}

const ToolTipImage = ({ urlin }: { urlin: string }) => {
  const [url, setUrl] = useState("");

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setUrl(img.src);
    };
    img.src = storageUrl(urlin);
  }, [urlin]);

  if (!url) return <></>;

  return <img src={storageUrl(url)} alt="floorplanpicture"></img>;
};

const ApartmentTooltip = ({ activeAnchor }: TArg) => {
  const status = activeAnchor?.getAttribute("data-tooltip-status");
  const rok = activeAnchor?.getAttribute("data-tooltip-rok");
  const price = activeAnchor?.getAttribute("data-tooltip-price");
  const charge = activeAnchor?.getAttribute("data-tooltip-charge");
  const size = activeAnchor?.getAttribute("data-tooltip-size");
  const apartmentid = activeAnchor?.getAttribute("data-tooltip-apartmentid");
  const floorplanpicture =
    activeAnchor?.getAttribute("data-tooltip-floorplanpicture") || "";

  const friendlyStatus =
    status === "available" ? "Till salu" : status === "sold" ? "Såld" : "Bokad";

  const statusColor =
    status === "available"
      ? "#00965f"
      : status === "sold"
        ? "#aaaaaa"
        : "#ffA500";

  return (
    <div className="w-60 pb-2 flex-col">
      <ul className="flex items-end">
        <li className="flex items-center w-20 h-10">
          <svg
            viewBox="0 0 318.29 119.64"
            width={80}
            height={30}
            className="mt-3"
          >
            <path
              fill={statusColor}
              fillOpacity={0.8}
              d="M0,0h258.42c33.11.37,59.86,27.58,59.87,59.87,0,31.17-24.94,57.82-56.84,59.78H0V0Z"
            />
          </svg>
          <div className="absolute text-white font-bold pl-1 pt-3">
            {friendlyStatus}
          </div>
        </li>
        <li className="flex-grow"></li>
        <li>
          <div className="font-bold text-lg">
            {rok} rok, {size} m<sup>2</sup>
          </div>
          <div className="text-xs -mt-1">Nr. {apartmentid}</div>
        </li>
      </ul>
      <div className="align-top mt-4">
        <ToolTipImage urlin={floorplanpicture} />
      </div>
      <ul className="flex items-end pl-4">
        <li className="">
          <ul>
            <li className="font-bold mt-2 text-left">Pris</li>
            <li className="text-xs">{price} kr</li>
          </ul>
        </li>
        <li className="flex-grow"></li>
        <li className="">
          <ul>
            <li className="font-bold mt-2 text-left">Avgift</li>
            <li className="text-xs">{charge} kr/mån</li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default ApartmentTooltip;
