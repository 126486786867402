interface TArg {
  content: string | null;
  activeAnchor: HTMLElement | null;
}

const InfoTooltip = ({ activeAnchor }: TArg) => {
  const info = activeAnchor?.getAttribute("data-tooltip-info") || "";
  const title = activeAnchor?.getAttribute("data-tooltip-title") || "";

  return (
    <div className="md:w-64 w-64">
      <div className="text-left md:text-base text-xs p-2 font-bold">
        {title}
      </div>
      {info && <div className="text-left md:text-sm text-xs p-2">{info}</div>}
    </div>
  );
};

export default InfoTooltip;
