import { useEffect, useMemo, useState } from "react";
import { Apartment, ApartmentStatus, Site } from "./CommonTypes";
// import { Column, TableInstance, useSortBy, useTable } from "react-table";
import {
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import PriceCell from "./PriceCell";
import StatusCell from "./StatusCell";
import { collection, doc, onSnapshot, setDoc } from "firebase/firestore";
import { db } from "./firebase";
import { FirebaseError } from "firebase/app";

const AdminApartmentTable = ({
  company,
  site,
}: {
  company: string;
  site: Site;
}) => {
  const columnHelper = createColumnHelper<Apartment>();
  const [data, setData] = useState<Apartment[]>([]);
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: "apartmentid", // Must be equal to the accessorKey of the coulmn you want sorted by default
      desc: false,
    },
  ]);

  useEffect(() => {
    if (!company || !site || !site.siteid) return;

    return onSnapshot(
      collection(db, `${company}/${site.siteid}/apartments`),
      (snapshot) => {
        const data2 = snapshot.docs.map((doc) => {
          const data = doc.data() as Omit<Apartment, "id">;
          return { ...data, id: doc.id } as Apartment;
        });
        setData(data2);
        if (initialLoading) setInitialLoading(false);
      },
    );
  }, []);

  const getHeaderName = (column: string) => {
    switch (column) {
      case "status":
        return "Status";
      case "apartmentid":
        return "Nr.";
      case "size":
        return "Boarea";
      case "rok":
        return "Rum";
      case "floor":
        return "Våning";
      case "price":
        return "Pris";
      case "charge":
        return "Avgift";
      default:
        return "";
    }
  };

  const sizeCell = (size: number) => {
    return size.toString() + " m²";
  };

  const handlePriceChange = (
    apartmentid: string,
    field: "price" | "charge",
    value: number,
  ) => {
    const apartment = data.find((a) => a.apartmentid === apartmentid);
    if (apartment) {
      const docRef = doc(
        db,
        `${company}/${site.siteid}/apartments/${apartment.id}`,
      );
      const update: {
        [field: string]: number;
      } = {};
      update[field] = value;
      setDoc(docRef, update, { merge: true })
        .then(() => {
          console.log("updated:", update);
        })
        .catch(() => {});
    }
  };

  const handleStatusChange = (
    apartmentid: string,
    value: "sold" | "available" | "reserved",
  ) => {
    const apartment = data.find((a) => a.apartmentid === apartmentid);

    if (apartment) {
      const docRef = doc(
        db,
        `${company}/${site.siteid}/apartments/${apartment.id}`,
      );

      setDoc(docRef, { status: value }, { merge: true })
        .then(() => {
          console.log("status updated:", value);
        })
        .catch((e: FirebaseError) => {
          console.log("status update failed:", e.message);
        });
    }
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor("apartmentid", {
        header: (info) => getHeaderName(info.column.id),
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("size", {
        header: (info) => getHeaderName(info.column.id),
        cell: (info) => sizeCell(info.getValue()),
      }),

      columnHelper.accessor("floor", {
        header: (info) => getHeaderName(info.column.id),
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("rok", {
        header: (info) => getHeaderName(info.column.id),
        cell: (info) => info.getValue(),
      }),

      columnHelper.accessor("price", {
        header: (info) => getHeaderName(info.column.id),
        cell: (info) => (
          <PriceCell
            price={info.getValue()}
            onChange={(value) => {
              handlePriceChange(
                info.row.getValue("apartmentid"),
                "price",
                value,
              );
            }}
          />
        ),
      }),
      columnHelper.accessor("charge", {
        header: (info) => getHeaderName(info.column.id),
        cell: (info) => (
          <PriceCell
            price={info.getValue()}
            onChange={(value) => {
              handlePriceChange(
                info.row.getValue("apartmentid"),
                "charge",
                value,
              );
            }}
          />
        ),
      }),

      columnHelper.accessor("status", {
        header: (info) => getHeaderName(info.column.id),
        cell: (info) => (
          <StatusCell
            status={info.getValue()}
            onChange={(value: ApartmentStatus) => {
              handleStatusChange(info.row.getValue("apartmentid"), value);
            }}
          />
        ),
      }),
    ],
    [initialLoading],
  );

  const table = useReactTable({
    data: data,
    columns: columns,
    state: {
      sorting: sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div className="flex w-full justify-center">
      <div className="pl-3 p-2 w-full shadow-md border-2 border-gray-100">
        <table className="w-full reacttable">
          <thead className="text-sm">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th className="text-left" key={header.id}>
                    {header.isPlaceholder ? null : (
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="text-xs">
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminApartmentTable;
