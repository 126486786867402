import { ApartmentStatus } from "./CommonTypes";

const StatusCell = ({
  status,
  onChange,
}: {
  status: string;
  onChange?: (value: ApartmentStatus) => void;
}) => {
  const validateStatus = (status: string) => {
    switch (status) {
      case "available":
        return "available";
      case "sold":
        return "sold";
      case "reserved":
        return "reserved";
      default:
        throw new Error("Invalid status");
    }
  };

  if (onChange) {
    return (
      <select
        name="status"
        id="status"
        className="w-[65px] h-8 text-xs appearance-none pl-1 pr-0 bg-right md:w-36 bg-no-repeat border border-gray-300 rounded-md"
        onChange={(e) => onChange(validateStatus(e.target.value))}
        defaultValue={status}
      >
        <option value="available">Till Salu</option>
        <option value="sold">Såld</option>
        <option value="reserved">Bokad</option>
      </select>
    );
  }

  switch (status) {
    case "available":
      return (
        <span className="flex items-center -ml-1">
          <svg width="16" height="16">
            <circle cx="8" cy="9" r="3" fill="green" />
          </svg>
          <span className="whitespace-nowrap">Till salu</span>
        </span>
      );
    case "sold":
      return (
        <span className="flex items-center -ml-1">
          <svg width="16" height="16">
            <circle cx="8" cy="9" r="3" fill="gray" />
          </svg>
          <span className="whitespace-nowrap">Såld</span>
        </span>
      );
    case "reserved":
      return (
        <span className="flex items-center -ml-1">
          <svg width="16" height="16">
            <circle cx="8" cy="9" r="3" fill="orange" />
          </svg>
          <span className="whitespace-nowrap">Bokad</span>
        </span>
      );
    default:
      return <span>{status}</span>;
  }
};

export default StatusCell;
