/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRecoilState, useRecoilValue } from "recoil";
import {
  apartmentFilter,
  apartmentOnTopState,
  hooveredApartmentId,
  showAvailableState,
} from "./recoil";
import { useState } from "react";
import Modal from "./Modal";
import crossURL from "./assets/cross.svg";
import ImageCarousel from "./ImageCarousel";
import { Link } from "react-router-dom";

function scrollToTop() {
  const container = document.getElementById("table-container");
  if (container) {
    container.scrollTo(0, 0);
  }
}

const ApartmentArea = ({
  apartmentId,
  d,
  toolTipPlace,
  toolTipId,
}: {
  apartmentId: string;
  d: string;
  toolTipPlace: string;
  toolTipId: string;
}) => {
  const apartments = useRecoilValue(apartmentFilter);
  const [overApartmentId, setOverApartmentId] =
    useRecoilState(hooveredApartmentId);
  const [, setApartmentOnTop] = useRecoilState(apartmentOnTopState);
  const [showAvailable] = useRecoilState(showAvailableState);
  const [showModal, setShowModal] = useState(false);

  if (!apartments) {
    return <></>;
  }

  const apartment = apartments.find((a) => a.apartmentid === apartmentId);
  if (!apartment) {
    return <></>;
  }

  const setDataToolTip = toolTipId
    ? {
        "data-tooltip-id": toolTipId,
        "data-tooltip-status": apartment.status,
        "data-tooltip-rok": apartment.rok,
        "data-tooltip-price": apartment.price,
        "data-tooltip-charge": apartment.charge,
        "data-tooltip-size": apartment.size,
        "data-tooltip-apartmentid": apartment.apartmentid,
        "data-tooltip-floorplanpicture": apartment.floorplanpicture,
        "data-tooltip-content": "",
        "data-tooltip-place": toolTipPlace,
      }
    : {};

  const classNames = [
    "location-path",
    apartment.status,
    showAvailable ? "show" : "",
    overApartmentId == apartmentId ? "showbold" : "",
  ];

  const pathMouseOver = () => {
    setApartmentOnTop(apartment);
    setOverApartmentId(apartmentId);
    scrollToTop();
  };

  const pathMouseOut = () => {
    setOverApartmentId("");
  };

  const pictures = apartment.pictures || [];
  return (
    <>
      <a
        id={"apartmentArea-" + apartmentId}
        href=""
        onClick={(e) => {
          e.preventDefault();
          setShowModal(true);
        }}
        className={"relative block "}
      >
        <path
          onMouseOver={() => pathMouseOver()}
          onMouseOut={() => pathMouseOut()}
          id={apartmentId}
          className={classNames.join(" ")}
          d={d}
          {...setDataToolTip}
        />
      </a>

      {showModal ? (
        <Modal>
          <div className="absolute bg-white top-1 bottom-1 right-1 left-1">
            <div className="">
              <button
                onClick={() => setShowModal(false)}
                className="m-3 absolute top-0 right-0 z-50"
              >
                <img src={crossURL} alt="close" className="" />
              </button>
              <ImageCarousel
                pictures={[apartment.floorplanpicture, ...pictures]}
                totalPictures={pictures.length + 1}
              />
            </div>
            <div className="absolute -bottom-6 md:bottom-2  h-16 w-full">
              <ul className="flex justify-center gap-10 md:gap-24">
                {apartment.interesturl && (
                  <Link to={apartment.interesturl} target="_blank">
                    <li className="">
                      <button className="bg-[#00965f] hover:opacity-90 text-white h-8 w-40 md:h-11 md:w-40 md:text-sm font-normal">
                        INTRESSEANMÄLAN
                      </button>
                    </li>
                  </Link>
                )}
                {apartment.sheeturl && (
                  <Link to={apartment.sheeturl} target="_blank">
                    <li className="">
                      <button className="bg-[#00965f] hover:opacity-90 text-white h-8 w-40 md:h-11 md:w-40 md:text-sm font-normal">
                        BOFAKTABLAD
                      </button>
                    </li>
                  </Link>
                )}
              </ul>
            </div>
          </div>
        </Modal>
      ) : null}
    </>
  );
};

export default ApartmentArea;
