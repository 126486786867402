import { useRecoilState, useRecoilValue } from "recoil";
import { auth, storageUrl } from "./firebase";
import { userData } from "./recoil";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import {
  sendSignInLinkToEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { FirebaseError } from "firebase/app";
import useSites from "./useSites";

const AdminPage = ({ company }: { company: string }) => {
  const [sites] = useSites(company);
  const user = useRecoilValue(userData);

  return (
    <>
      <div className="planner-wrapper m-5 flex flex-wrap gap-4 ">
        {sites.map((site) => {
          if (user && !site.admins.includes(user.id)) return false;

          return (
            <div key={site.siteid}>
              <Link to={`/${company}/admin/${site.sitename}`}>
                <button className="bg-[#00965f] hover:opacity-90 text-white py-2 px-4 w-46 pb-4">
                  {site.sitename}
                  <img
                    src={storageUrl(site.overviewpicture)}
                    alt="floorplan"
                    className="m-1 rounded-3xl max-w-40"
                  />
                </button>
              </Link>
            </div>
          );
        })}
      </div>
    </>
  );
};

const AdminPortal = () => {
  const { company } = useParams();

  const [user, setUser] = useRecoilState(userData);
  const [email, setEmail] = useState("");
  const [emailRequest, setEmailRequest] = useState("");
  const [password, setPassword] = useState("");
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const [emailSent, setEmailSent] = useState(false);

  if (!company) {
    throw new Error("no company provided to admin");
  }

  if (!user) return null;

  if (user && user.id !== "anonymous") {
    return <AdminPage company={company} />;
  }

  const onLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        setUser({ id: user.uid });
        setEmail("");
        setPassword("");
      })
      .catch((error: FirebaseError) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        if (errorCode == "auth/invalid-credential") {
          setLoginErrorMessage("Wrong e-mail address or password");
        } else if (errorCode == "auth/invalid-email") {
          setLoginErrorMessage("Wrong e-mail adress");
        } else if (errorCode == "auth/too-many-requests") {
          setLoginErrorMessage(
            "To many requests, please wait a while and try again",
          );
        } else {
          setLoginErrorMessage(errorCode + " " + errorMessage);
        }
        console.log(errorCode);
      });
  };

  const onRequestEmailLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    sendSignInLinkToEmail(auth, emailRequest, {
      url: `https://bostad-cloud.pintar.se/${company}/admin`,
      handleCodeInApp: true,
    })
      .then(() => {
        window.localStorage.setItem("emailForSignIn", emailRequest);
        setEmail("");
        setPassword("");
        setEmailRequest("");
        setEmailSent(true);
      })
      .catch((error: FirebaseError) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        if (errorCode == "auth/invalid-email") {
          setLoginErrorMessage("Wrong e-mail adress");
        } else if (errorCode == "auth/too-many-requests") {
          setLoginErrorMessage(
            "To many requests, please wait a while and try again",
          );
        } else {
          setLoginErrorMessage(errorCode + " " + errorMessage);
        }
        console.log(errorCode);
      });
  };

  if (emailSent) {
    return (
      <div className="planner-wrapper relative m-5 flex justify-evenly">
        <div className="planner-wrapper relative m-5 w-full">
          <h1>Länk för att logga in har skickats till din mailkorg</h1>
        </div>
      </div>
    );
  }

  return (
    <div className="planner-wrapper relative flex justify-evenly flex-col md:flex-row">
      <div className="planner-wrapper relative m-5 w-auto">
        <form id="frmLogin" onSubmit={onLogin}>
          <div className="flex flex-col justify-center">
            <h2 className="font-bold">Logga in med lösenord</h2>

            <div className="form-group flex flex-col ">
              <label htmlFor="txtEmail">E-mail adress</label>
              <input
                type="email"
                className="form-control"
                id="txtEmail"
                placeholder="Ange E-mail"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group flex flex-col">
              <label htmlFor="txtPass">Lösenord</label>
              <input
                type="password"
                className="form-control"
                id="txtPass"
                placeholder="Lösenord"
                name="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            {loginErrorMessage && (
              <div className="text-red-500">{loginErrorMessage}</div>
            )}
            <button
              type="submit"
              className="bg-[#00965f] hover:opacity-90 text-white py-1 px-4 w-full mt-5 btn"
            >
              Login
            </button>
            <br />
          </div>
        </form>
      </div>
      <div className="planner-wrapper relative m-5 w-auto">
        <form id="frmRequestEmail" onSubmit={onRequestEmailLogin}>
          <div className="flex flex-col justify-center">
            <h2 className="font-bold">Logga in med E-mail</h2>

            <div className="form-group flex flex-col ">
              <label htmlFor="txtEmail">E-mail adress</label>
              <input
                type="email"
                className="form-control"
                id="emailrequest"
                placeholder="Ange E-mail"
                name="email"
                onChange={(e) => setEmailRequest(e.target.value)}
              />
            </div>
            <button
              type="submit"
              className="bg-[#00965f] hover:opacity-90 text-white py-1 px-4 w-full mt-5 btn"
            >
              Request Email
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminPortal;
