import { QueryStatus, useQuery } from "@tanstack/react-query";

import fetchSite from "./fetchSite";
import { Site } from "./CommonTypes";

export default function useSite(company: string, site: string) {
  const results = useQuery({
    queryKey: ["site", site, "company", company],
    queryFn: () => fetchSite(site, company),
  });

  if (results.data === undefined) {
    return [{}, results.status] as [Site, QueryStatus];
  } else {
    return [results.data[0], results.status] as [Site, QueryStatus];
  }
}
