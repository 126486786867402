import { useState } from "react";
import { Apartment } from "./CommonTypes";

import {
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import ApartmentTableRow from "./ApartmentTableRow";

const ApartmentTable = ({ apartments }: { apartments: Apartment[] }) => {
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: "rok", // Must be equal to the accessorKey of the coulmn you want sorted by default
      desc: false,
    },
  ]);

  const columnHelper = createColumnHelper<Apartment>();

  const getHeaderName = (column: string) => {
    switch (column) {
      case "status":
        return "Status";
      case "apartmentid":
        return "Nr.";
      case "size":
        return "Boarea";
      case "rok":
        return "Rum";
      case "floor":
        return "Våning";
      case "price":
        return "Pris";
      case "charge":
        return "Avgift";
      default:
        return "";
    }
  };

  const columns = [
    columnHelper.accessor("status", {
      header: (info) => getHeaderName(info.column.id),
    }),
    columnHelper.accessor("rok", {
      header: (info) => getHeaderName(info.column.id),
    }),
    columnHelper.accessor("price", {
      header: (info) => getHeaderName(info.column.id),
    }),
    columnHelper.accessor("charge", {
      header: (info) => getHeaderName(info.column.id),
    }),
    columnHelper.accessor("size", {
      header: (info) => getHeaderName(info.column.id),
    }),
    columnHelper.accessor("floor", {
      header: (info) => getHeaderName(info.column.id),
    }),
    columnHelper.accessor("apartmentid", {
      header: (info) => getHeaderName(info.column.id),
    }),
  ];

  const table = useReactTable({
    data: apartments,
    columns: columns,
    state: {
      sorting: sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div className="flex w-full justify-center">
      <div
        className="pl-3 p-2 w-full shadow-md border-2 border-gray-100  md:overflow-auto md:max-h-56"
        id="table-container"
      >
        <table className="w-full reacttable">
          <thead className="text-sm">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    {...{
                      className:
                        header.id === "apartmentid"
                          ? "text-left pr-3 whitespace-nowrap hidewhenmobile"
                          : "text-left pr-2 whitespace-nowrap",
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? "cursor-pointer select-none"
                            : "",
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {{
                          asc: (
                            <svg
                              viewBox="0 0 512 512"
                              className="inline-block rotate-90 w-4 h-4"
                            >
                              <polygon points="352,128.4 319.7,96 160,256 160,256 160,256 319.7,416 352,383.6 224.7,256 " />
                            </svg>
                          ),
                          desc: (
                            <svg
                              viewBox="0 0 512 512"
                              className="inline-block -rotate-90 w-4 h-4"
                            >
                              <polygon points="352,128.4 319.7,96 160,256 160,256 160,256 319.7,416 352,383.6 224.7,256 " />
                            </svg>
                          ),
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="text-xs">
            <ApartmentTableRow
              row={table.getRowModel().rows[0]}
              isTopRow={true}
            />

            {table.getRowModel().rows.map((row) => {
              return <ApartmentTableRow key={row.id} row={row} />;
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ApartmentTable;
