import { Location } from "./CommonTypes";
import { collection, getDocs } from "firebase/firestore";
import { db } from "./firebase";

const fetchLocations = async (
  viewId: string,
  company: string,
  site: string,
) => {
  if (process.env.NODE_ENV === "development")
    console.log("fetching locations", viewId, site, company);

  const colRef = collection(db, `${company}/${site}/views/${viewId}/locations`);

  const results = await getDocs(colRef);

  return results.docs.map((doc) => {
    const data = doc.data() as Omit<Location, "id">;
    return { ...data, id: doc.id } as Location;
  });
  // return results.docs.map((doc) => doc.data() as Location);
};

export default fetchLocations;
