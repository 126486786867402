/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { apartmentsState } from "./recoil";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "./firebase";
import { Apartment } from "./CommonTypes";

export const useApartments = (company: string, site: string) => {
  const [apartments, setApartments] = useRecoilState(apartmentsState);

  useEffect(() => {
    if (!company || !site) return;

    if (process.env.NODE_ENV === "development")
      console.log("fetching apartments", site, company);

    return onSnapshot(
      collection(db, `${company}/${site}/apartments`),
      (snapshot) => {
        const data2 = snapshot.docs.map((doc) => {
          const data = doc.data() as Omit<Apartment, "id">;
          return { ...data, id: doc.id } as Apartment;
        });
        setApartments(data2);
      },
    );
  }, [company, site]);

  return apartments;
};
