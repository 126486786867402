import { atom, selector } from "recoil";
import { Apartment, Info, View } from "./CommonTypes";
import { User } from "./CommonTypes";

export const viewlist = atom({
  key: "viewlist",
  default: [] as View[],
});

export const userData = atom({
  key: "userData",
  default: null as User | null,
});

export const hooveredApartmentId = atom({
  key: "hooveredApartmentId",
  default: "",
});

export const apartmentOnTopState = atom({
  key: "apartmentOnTopState",
  default: null as Apartment | null,
});

export const apartmentsState = atom({
  key: "apartments",
  default: [] as Apartment[],
});

export const infoState = atom({
  key: "infos",
  default: [] as Info[],
});

export const showAvailableState = atom({
  key: "showAvailableState",
  default: false as boolean,
});

export const showROKState = atom({
  key: "showROKState",
  default: [true, true, true, true, true, true] as boolean[],
});

export const apartmentFilter = selector({
  key: "apartmentFilter",
  get: ({ get }) => {
    const rokFilter = get(showROKState);

    const filter = (a: Apartment) => {
      if (a.rok == 1) return rokFilter[0];
      if (a.rok == 2) return rokFilter[1];
      if (a.rok == 3) return rokFilter[2];
      if (a.rok == 4) return rokFilter[3];
      if (a.rok == 5) return rokFilter[4];
      if (a.rok == 6) return rokFilter[5];
      return true;
    };

    return get(apartmentsState).filter(filter);
  },
});

export const avalableROKInApartments = selector({
  key: "avalableROKInApartments",
  get: ({ get }) => {
    const apartments = get(apartmentsState);
    const availableROK = [0, 0, 0, 0, 0, 0] as number[];
    apartments.forEach((a) => {
      if (a.rok == 1) availableROK[0]++;
      if (a.rok == 2) availableROK[1]++;
      if (a.rok == 3) availableROK[2]++;
      if (a.rok == 4) availableROK[3]++;
      if (a.rok == 5) availableROK[4]++;
      if (a.rok == 6) availableROK[5]++;
    });
    return availableROK;
  },
});
