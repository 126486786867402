import { QueryStatus, useQuery } from "@tanstack/react-query";

import fetchLocations from "./fetchLocations";
import { Location } from "./CommonTypes";

export default function useLocations(
  company: string,
  site: string,
  viewId: string,
) {
  const results = useQuery({
    queryKey: ["locations", viewId, "company", company, "site", site],
    queryFn: () => fetchLocations(viewId, company, site),
  });

  return [results?.data ?? [], results.status] as [Location[], QueryStatus];
}
