import { useEffect, useState } from "react";

import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import BuildingView from "./BuildingView";
import useViews from "./useViews";
import arrowLeft from "./assets/arrow_left.svg";
import arrowRight from "./assets/arrow_right.svg";
import Compass from "./Compass";
import logo from "./assets/ps_symbol.svg";
import { Tooltip } from "react-tooltip";
import ApartmentTooltip from "./ApartmentTooltip";
import ViewTooltip from "./ViewTooltip";
import InfoTooltip from "./InfoTooltip";

const BuildingViewCarousel = ({
  company,
  siteId,
  overviewPicture,
}: {
  company: string;
  siteId: string;
  overviewPicture: string;
}) => {
  const [siteViews] = useViews(company, siteId);
  const [aspect, setAspect] = useState([0, 0]);
  const [loaded, setLoaded] = useState(false);

  // const [compass] = useRecoilValue(compassDirectionState);

  // Create a useQuery for Views
  // use useEffect to preload the images, show a loadanimation while a useState(loaded) is false

  const isMobile = () => {
    return window.matchMedia("(max-width: 767px)").matches;
  };

  useEffect(() => {
    if (siteViews.length > 0) {
      const img = new Image();
      img.onload = () => {
        setAspect([img.width, img.height]);
        setLoaded(true);
      };

      img.src = siteViews[0].daypicture;
    }
  }, [siteViews]);

  if (!loaded) {
    return (
      <section className="sec-loading">
        <div className="one">
          <img className="bottom-10 w-32 h-32" alt="logo" src={logo}></img>
        </div>
      </section>
    );
  }

  const isMobileDevice = isMobile();

  return (
    <CarouselProvider
      naturalSlideWidth={aspect[0]}
      naturalSlideHeight={aspect[1]}
      totalSlides={siteViews.length}
      className="relative"
    >
      <Slider>
        {siteViews.map((view, index) => (
          <Slide key={view.viewid} index={index}>
            <BuildingView
              company={company}
              siteid={siteId}
              viewid={view.viewid}
              svgsize={view.svgsize}
              picture={view.daypicture}
              compass={view.compass}
            />
          </Slide>
        ))}
      </Slider>

      <Tooltip
        className="tooltipmedia"
        id={"apartment-tooltip"}
        render={ApartmentTooltip}
        closeEvents={{ click: true, mouseleave: true, blur: true }}
        // clickable
        // openOnClick
        style={{
          backgroundColor: "white",
          color: "black",
          paddingLeft: "0px",
          borderRadius: "8px",
          zIndex: 10,
        }}
      />
      <Tooltip
        className="tooltipmedia"
        id={"view-tooltip"}
        render={ViewTooltip}
        closeEvents={{ click: true, mouseleave: true, blur: true }}
        // clickable
        // openOnClick
        opacity={0.95}
        style={{
          backgroundColor: "white",
          color: "black",
          padding: "0px",
          borderRadius: "8px",
          zIndex: 10,
        }}
      />
      <Tooltip
        className=""
        id={"info-tooltip"}
        render={InfoTooltip}
        closeEvents={{
          click: isMobileDevice,
          mouseleave: !isMobileDevice,
          blur: !isMobileDevice,
        }}
        clickable={isMobileDevice}
        openOnClick={isMobileDevice}
        style={{
          backgroundColor: "white",
          color: "black",
          padding: "0px",
          borderRadius: "8px",
          zIndex: 10,
        }}
      />
      <img
        className="absolute right-2 bottom-14  opacity-90 w-6 h-6 md:h-10 md:w-10"
        alt="logo"
        src={logo}
      ></img>
      <Compass siteViews={siteViews} overviewPicture={overviewPicture} />
      <div className="flex justify-center items-center relative h-12 transform ">
        <ButtonBack className="w-9 transform translate-x-1/2">
          <img
            src={arrowLeft}
            alt="arrow left"
            className="w-8 h-8 transform -translate-x-1/2 hover:h-9 hover:w-9"
          />
        </ButtonBack>
        <div className="w-44"></div>
        <ButtonNext className="w-9 transform translate-x-1/2">
          <img
            src={arrowRight}
            alt="arrow left"
            className="w-8 h-8 transform -translate-x-1/2 hover:h-9 hover:w-9"
          />
        </ButtonNext>
      </div>

      <div
        id="modal"
        className="absolute top-0 left-0 block align-middle z-20  bg-[#00965f] w-full h-full invisible"
      ></div>
    </CarouselProvider>
  );
};

export default BuildingViewCarousel;
