import { QueryStatus, useQuery } from "@tanstack/react-query";

import fetchSites from "./fetchSites";
import { Site } from "./CommonTypes";

export default function useSites(company: string) {
  const results = useQuery({
    queryKey: ["company", company],
    queryFn: () => fetchSites(company),
  });

  if (results.data === undefined) {
    return [[], results.status] as [Site[], QueryStatus];
  } else {
    return [results.data, results.status] as [Site[], QueryStatus];
  }
}
