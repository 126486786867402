import { createElement, useCallback, useEffect, useState } from "react";
import { storageUrl } from "./firebase";

interface TArg {
  content: string | null;
  activeAnchor: HTMLElement | null;
}

const ToolTipImage = ({ urlin }: { urlin: string }) => {
  const [url, setUrl] = useState("");

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setUrl(img.src);
    };
    img.src = storageUrl(urlin);
  }, [urlin]);

  if (!url) return <></>;

  return <img src={storageUrl(url)} alt="floorplanpicture"></img>;
};

const ViewTooltip = ({ activeAnchor }: TArg) => {
  const picture = activeAnchor?.getAttribute("data-tooltip-picture") || "";
  const direction = activeAnchor?.getAttribute("data-tooltip-direction") || "";

  const directionToString = (d: string) => {
    if (d === "S" || d === "SSE" || d === "SSW") return "Nord";
    if (d === "E" || d === "ENE" || d === "ESE") return "Väst";
    if (d === "W" || d === "WNW" || d === "WSW") return "Öst";
    if (d === "N" || d === "NNE" || d === "NNW") return "Syd";
    if (d === "SE") return "NordVäst";
    if (d === "SW") return "NordOst";
    if (d === "NE" || d === "NNE") return "SydVäst";
    if (d === "NW" || d === "") return "SydOst";
  };

  return (
    <div className="w-60">
      <div className="p-2">
        <ToolTipImage urlin={picture} />
      </div>
      <div className="text-center text-base mb-3">
        Kameravy mot {directionToString(direction)}
      </div>
    </div>
  );
};

export default ViewTooltip;
