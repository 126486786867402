import { useState } from "react";

const PriceCell = ({
  price,
  onChange,
}: {
  price: number;
  onChange?: (value: number) => void;
}) => {
  const [value, setValue] = useState(price);

  if (!onChange) {
    return (
      <span className="whitespace-nowrap">
        {price.toLocaleString("sv-SE") + " kr"}
      </span>
    );
  }

  const validatePrice = (price: string) => {
    const priceAsNumber = parseInt(price);
    if (isNaN(priceAsNumber)) {
      return 0;
    }
    return priceAsNumber;
  };

  return (
    <>
      <input
        type="text"
        className="md:w-36 w-20 h-8 text-xs bg-no-repeat border border-gray-300 rounded-md"
        value={value}
        onChange={(e) => setValue(validatePrice(e.currentTarget.value))}
        onBlur={(e) => onChange(validatePrice(e.currentTarget.value))}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            e.currentTarget.blur();
          }
        }}
      ></input>
    </>
  );
};

export default PriceCell;
