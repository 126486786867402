import { Info } from "./CommonTypes";
import { collection, getDocs } from "firebase/firestore";
import { db } from "./firebase";

const fetchInfos = async (company: string, site: string) => {
  if (process.env.NODE_ENV === "development")
    console.log("fetching info", site, company);

  const colRef = collection(db, `${company}/${site}/info`);

  const results = await getDocs(colRef);

  return results.docs.map((doc) => {
    const data = doc.data() as Omit<Info, "id">;
    return { ...data, id: doc.id } as Info;
  });
  // return results.docs.map((doc) => doc.data() as Info);
};

export default fetchInfos;
