import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RecoilRoot } from "recoil";
import "react-tooltip/dist/react-tooltip.css";
import NavBar from "./NavBar";
import Planner from "./Planner";
import AdminPortal from "./Admin";
import AdminSite from "./AdminSite";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      gcTime: Infinity,
    },
  },
});

const App = () => {
  return (
    <RecoilRoot>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <div className="max-w-5xl m-auto">
            <NavBar />

            <Routes>
              <Route
                path="/"
                element={<Navigate to="/enklahem/Klapperstensvagen" replace />}
              />
              <Route path="/:company/:sitename/" element={<Planner />} />
              <Route path="/:company/admin" element={<AdminPortal />} />
              <Route path="/:company/admin/:sitename" element={<AdminSite />} />
            </Routes>
          </div>
        </QueryClientProvider>
      </BrowserRouter>
    </RecoilRoot>
  );
};

const container = document.getElementById("root");
if (!container) {
  throw new Error("no container to render to");
}

const root = createRoot(container);
root.render(<App />);
