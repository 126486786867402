/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext } from "react";
import { CarouselContext } from "pure-react-carousel";
import useViews from "./useViews";
import { showAvailableState } from "./recoil";
import { useRecoilState } from "recoil";

const ViewArea = ({
  id,
  d,
  company,
  siteid,
  toolTipPlace,
  toolTipId,
}: {
  id: string;
  d: string;
  company: string;
  siteid: string;
  toolTipPlace: string;
  toolTipId: string;
}) => {
  const carouselContext = useContext(CarouselContext);
  const [views] = useViews(company, siteid);
  const [showAvailable] = useRecoilState(showAvailableState);

  const classNames = ["view-path", showAvailable ? "show" : ""];

  const changeSlide = () => {
    carouselContext.setStoreState({
      currentSlide: (id as unknown as number) / 1,
    });
  };

  const view = views[+id];
  const setDataToolTip = toolTipId
    ? {
        "data-tooltip-id": toolTipId,
        "data-tooltip-picture": view.daypicture,
        "data-tooltip-direction": view.compass,
        "data-tooltip-content": "dsadsad",
        "data-tooltip-place": toolTipPlace,
      }
    : {};

  return (
    <a
      id={"viewArea-" + id}
      href=""
      onClick={(e) => {
        e.preventDefault();
        changeSlide();
      }}
      className={"relative block "}
    >
      <path
        id={id.toString()}
        className={classNames.join(" ")}
        d={d}
        {...setDataToolTip}
      />
    </a>
  );
};

export default ViewArea;
