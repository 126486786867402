import { Site } from "./CommonTypes";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "./firebase";

const fetchSite = async (site: string, company: string) => {
  if (process.env.NODE_ENV === "development")
    console.log("fetching site", site, company);

  const colRef = query(
    collection(db, `/${company}`),
    where("sitename", "==", site),
  );

  const results = await getDocs(colRef);

  return results.docs.map((doc) => {
    const data = doc.data() as Omit<Site, "siteid">;
    return { ...data, siteid: doc.id } as Site;
  });
};

export default fetchSite;
