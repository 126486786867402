import useLocations from "./useLocations";
import { storageUrl } from "./firebase";
import ApartmentArea from "./ApartmentArea";
import ViewArea from "./ViewArea";
import InfoArea from "./InfoArea";
import { useInfos } from "./useInfos";

interface BuildingViewArgs {
  company: string;
  siteid: string;
  viewid: string;
  svgsize: string;
  picture: string;
  compass:
    | "N"
    | "NNW"
    | "NW"
    | "WNW"
    | "W"
    | "WSW"
    | "SW"
    | "SSW"
    | "S"
    | "SSE"
    | "SE"
    | "ESE"
    | "E"
    | "ENE"
    | "NE"
    | "NNE";
}

const BuildingView = ({
  company,
  siteid,
  viewid,
  picture,
  svgsize,
  compass,
}: BuildingViewArgs) => {
  const [locations] = useLocations(company, siteid, viewid);
  const [infos] = useInfos(company, siteid);

  // const [aspect, setAspect] = useState([0, 0]);
  // useEffect(() => {
  //   const img = new Image();
  //   img.onload = () => {
  //     setAspect([img.width, img.height]);
  //   };
  //   img.src = picture;
  // }, [picture]);

  // if (!locations.length) {
  //   return <></>;
  // }

  return (
    <div className="relative inline-block">
      <img
        alt="ViewImage"
        src={storageUrl(picture)}
        className={"background-img " + compass + " block max-w-full h-auto"}
      />
      <svg
        className="absolute top-0 left-0"
        viewBox={`0 0 ${svgsize}`}
        preserveAspectRatio="none"
      >
        {locations.map((l) => {
          if (l.type === "apartment") {
            return (
              <ApartmentArea
                key={l.id}
                apartmentId={l.ref_id}
                d={l.coordinates}
                toolTipPlace={l.tooltipplace}
                toolTipId={"apartment-tooltip"}
              />
            );
          } else if (l.type === "view") {
            return (
              <ViewArea
                company={company}
                siteid={siteid}
                key={l.id}
                id={l.ref_id}
                d={l.coordinates}
                toolTipPlace={l.tooltipplace}
                toolTipId={"view-tooltip"}
              />
            );
          } else if (l.type === "info") {
            const info = infos.find((i) => i.id === l.ref_id);
            if (!info) return undefined;

            return (
              <InfoArea
                info={info}
                key={l.id}
                d={l.coordinates}
                toolTipPlace={l.tooltipplace}
                toolTipId={"info-tooltip"}
              />
            );
          }
        })}
      </svg>
    </div>
  );
};

export default BuildingView;
