import { useContext } from "react";
import camera from "./assets/camera.svg";
import cameraChecked from "./assets/camera_checked.svg";
import { CarouselContext } from "pure-react-carousel";

const Camera = ({
  degrees,
  slide,
  current,
}: {
  degrees: number;
  slide: number;
  current: number;
}) => {
  const carouselContext = useContext(CarouselContext);

  if (slide < 0) return <div></div>;

  const changeSlide = () => {
    if (slide !== current) {
      carouselContext.setStoreState({
        currentSlide: slide,
      });
    }
  };

  const cameraURL = slide === current ? cameraChecked : camera;
  return (
    <button onClick={() => changeSlide()}>
      <img
        alt="camera"
        src={cameraURL}
        className="w-6 h-6"
        style={{ transform: `rotate(${degrees}deg)` }}
      ></img>
    </button>
  );
};

export default Camera;
