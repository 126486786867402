import { useContext, useEffect, useState } from "react";
import compassUrl from "./assets/compass.svg";
import { CarouselContext } from "pure-react-carousel";
import Camera from "./Camera";
import { View } from "./CommonTypes";
import { storageUrl } from "./firebase";

const Compass = ({
  siteViews,
  overviewPicture,
}: {
  siteViews: View[];
  overviewPicture: string;
}) => {
  const carouselContext = useContext(CarouselContext);
  const [showViewSelector, setShowViewSelector] = useState(false);

  const [currentSlide, setCurrentSlide] = useState(
    carouselContext.state.currentSlide,
  );

  useEffect(() => {
    function onChange() {
      setCurrentSlide(carouselContext.state.currentSlide);
    }
    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext]);

  const directions = siteViews.map((view) => view.compass);

  // Function that translate view.compass to degrees
  const translateCompass = (compass: string) => {
    let degrees = 0;

    switch (compass) {
      case "N":
        degrees = 180;
        break;
      case "NNE":
        degrees = 157.5;
        break;
      case "NE":
        degrees = 135;
        break;
      case "ENE":
        degrees = 112.5;
        break;
      case "E":
        degrees = 90;
        break;
      case "ESE":
        degrees = 67.5;
        break;
      case "SE":
        degrees = 45;
        break;
      case "SSE":
        degrees = 22.5;
        break;
      case "S":
        degrees = 0;
        break;
      case "SSW":
        degrees = 337.5;
        break;
      case "SW":
        degrees = 315;
        break;
      case "WSW":
        degrees = 292.5;
        break;
      case "W":
        degrees = 270;
        break;
      case "WNW":
        degrees = 247.5;
        break;
      case "NW":
        degrees = 225;
        break;
      case "NNW":
        degrees = 202.5;
        break;
      default:
        degrees = 180;
        break;
    }

    return `rotate(${degrees}deg)`;
  };

  return (
    <>
      <div
        className="overlay"
        style={{ visibility: showViewSelector ? "visible" : "hidden" }}
        onClick={() => setShowViewSelector(false)}
      ></div>
      <div
        className="absolute w-56 h-56 -bottom-3 left-1/2  z-10 p-3 rounded-md opacity-90 md:w-96 md:h-96 md:-bottom-16"
        id="viewSelector"
        style={{
          backgroundColor: "white",
          backgroundImage: "url(" + storageUrl(overviewPicture) + ")",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          color: "black",
          visibility: showViewSelector ? "visible" : "hidden",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div className="flex flex-col justify-between h-full">
          <div className="flex justify-between">
            <Camera
              degrees={45}
              slide={directions.indexOf("NW")}
              current={currentSlide}
            />
            <Camera
              degrees={67.5}
              slide={directions.indexOf("NNW")}
              current={currentSlide}
            />
            <Camera
              degrees={90}
              slide={directions.indexOf("N")}
              current={currentSlide}
            />
            <Camera
              degrees={112.5}
              slide={directions.indexOf("NNE")}
              current={currentSlide}
            />
            <Camera
              degrees={135}
              slide={directions.indexOf("NE")}
              current={currentSlide}
            />
          </div>
          <div className="flex justify-between middle-row">
            <div className="flex items-center">
              <Camera
                degrees={22.5}
                slide={directions.indexOf("WNW")}
                current={currentSlide}
              />
            </div>
            <div></div>
            <div className="flex items-center">
              <Camera
                degrees={157.5}
                slide={directions.indexOf("ENE")}
                current={currentSlide}
              />
            </div>
          </div>
          <div className="flex justify-between middle-row">
            <div className="flex items-center">
              <Camera
                degrees={0}
                slide={directions.indexOf("W")}
                current={currentSlide}
              />
            </div>
            <div></div>
            <div className="flex items-center">
              <Camera
                degrees={180}
                slide={directions.indexOf("E")}
                current={currentSlide}
              />
            </div>
          </div>
          <div className="flex justify-between middle-row">
            <div className="flex items-center">
              <Camera
                degrees={337.5}
                slide={directions.indexOf("WSW")}
                current={currentSlide}
              />
            </div>
            <div></div>
            <div className="flex items-center">
              <Camera
                degrees={202.5}
                slide={directions.indexOf("ESE")}
                current={currentSlide}
              />
            </div>
          </div>
          <div className="flex justify-between">
            <Camera
              degrees={315}
              slide={directions.indexOf("SW")}
              current={currentSlide}
            />
            <Camera
              degrees={337.5}
              slide={directions.indexOf("SSW")}
              current={currentSlide}
            />
            <Camera
              degrees={270}
              slide={directions.indexOf("S")}
              current={currentSlide}
            />
            <Camera
              degrees={247.5}
              slide={directions.indexOf("SSE")}
              current={currentSlide}
            />
            <Camera
              degrees={225}
              slide={directions.indexOf("SE")}
              current={currentSlide}
            />
          </div>
        </div>
      </div>
      <button
        data-tooltip-id="viewSelector"
        data-tooltop-place="top"
        onClick={() => setShowViewSelector(!showViewSelector)}
        style={{
          transform:
            "translate(-50%, -50%) " +
            translateCompass(directions[currentSlide]),
        }}
        className={
          "compass absolute left-1/2 w-20 h-20 z-1 hover:w-[82px] hover:h-[82px]"
        }
      >
        <img src={showViewSelector ? compassUrl : compassUrl} alt={"compass"} />
      </button>
    </>
  );
};

export default Compass;
