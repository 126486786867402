import { useEffect, useState } from "react";
import { auth } from "./firebase";
import { useRecoilState, useRecoilValue } from "recoil";
import { avalableROKInApartments, showROKState, userData } from "./recoil";
import { matchRoutes, useLocation } from "react-router-dom";
import {
  isSignInWithEmailLink,
  onAuthStateChanged,
  signInWithEmailLink,
  signOut,
} from "firebase/auth";
import { FirebaseError } from "firebase/app";
import { Tooltip } from "react-tooltip";
import RadioButton from "./RadioButton";
import { showAvailableState } from "./recoil";

// Top navbar
export default function NavBar() {
  const [user, setUser] = useRecoilState(userData);

  const [showAvailable, setShowAvailable] = useRecoilState(showAvailableState);
  const availableROK = useRecoilValue(avalableROKInApartments);

  const [showFilter, setShowFilter] = useState(false);
  const [rokFilter, setRokFilter] = useRecoilState(showROKState);

  //const { bostad } = useHistorey

  const location = useLocation();
  const routes = [{ path: "/:company/admin/*" }];

  const route = matchRoutes(routes, location);
  const routeParam = route ? route[0].params.company : "";
  const isAdminPage = routeParam;

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user && user.uid) {
        setUser({ id: user.uid });
      } else {
        setUser({ id: "anonymous" });
      }
    });
  }, []);

  if (user && user.id === "anonymous") {
    // User is null if useEffect have not run
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        email = window.prompt("Please provide your email for confirmation");
      }
      if (email) {
        signInWithEmailLink(auth, email, window.location.href)
          .then(() => {
            window.localStorage.removeItem("emailForSignIn");
            // setUser({ id: result.user.uid });
          })
          .catch((error: FirebaseError) => {
            throw new Error(error.message);
          });
      }
    }
  }
  function toggleShowAvailable(set?: boolean) {
    setShowAvailable(set || !showAvailable);
  }

  function toggleROK(rok: number) {
    const newRokFilter = [...rokFilter];
    newRokFilter[rok] = !newRokFilter[rok];
    toggleShowAvailable(true);
    setRokFilter(newRokFilter);
  }

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        setUser({ id: "anonymous" });
      })
      .catch((error: FirebaseError) => {
        console.log(error);
        // An error happened.
      });
  };

  if (isAdminPage) {
    return (
      <header className="mb-2 mt-2">
        <nav>
          <ul className="flex justify-between flex-wrap">
            <li className="ml-3 mr-5 font-extrabold text-base">ADMIN PAGE</li>
            {user && user.id !== "anonymous" && (
              <li className="mr-2 text-xs flex items-center md:text-sm md:mr-5">
                <div className="flex justify-end">
                  <button onClick={() => handleSignOut()}>Sign out</button>
                </div>
              </li>
            )}
          </ul>
        </nav>
      </header>
    );
  }

  return (
    <header className="mb-2 mt-2">
      <nav>
        <div className="flex justify-between flex-wrap">
          <ul className="flex list-none p-0 ">
            <li className="ml-3 mr-5 font-bold">Bostadsrätter</li>
            <li className="mr-2 text-xs flex items-center">
              <svg width="16" height="16">
                <circle cx="8" cy="9" r="3" fill="green" />
              </svg>
              <span className="whitespace-nowrap">Till salu</span>
            </li>
            <li className="mr-2 text-xs flex items-center">
              <svg width="16" height="16">
                <circle cx="8" cy="9" r="3" fill="orange" />
              </svg>
              Bokad
            </li>
            <li className="mr-2 text-xs flex items-center">
              <svg width="16" height="16">
                <circle cx="8" cy="9" r="3" fill="grey" />
              </svg>
              Såld
            </li>
          </ul>

          <ul className="flex list-none ml-3 justify-between flex-wrap ">
            <li className="mr-3 ml-auto font-bold">
              <button
                className="flex items-center mr-3"
                onClick={() => toggleShowAvailable()}
              >
                Visa lägenheter till salu
                <RadioButton checked={showAvailable} />
              </button>
            </li>

            <Tooltip
              id="filterBox"
              openOnClick
              clickable
              afterShow={() => setShowFilter(true)}
              afterHide={() => setShowFilter(false)}
              className="z-10 mt-2 bg-white -ml-1"
              noArrow
              place="bottom-end"
              style={{
                backgroundColor: "white",
                color: "black",
                visibility: showFilter ? "visible" : "hidden",
              }}
            >
              <ul>
                {rokFilter.map((rok, index) => {
                  const roomsAvailable = availableROK[index];

                  if (!roomsAvailable) return "";

                  return (
                    <li key={`rok-${index}`} className="mb-2">
                      <button
                        className="flex items-center font-bold"
                        onClick={() => toggleROK(index)}
                      >
                        {index + 1} rok
                        <RadioButton checked={rokFilter[index]} />
                      </button>
                    </li>
                  );
                })}
              </ul>
            </Tooltip>
            <button
              data-tooltip-id="filterBox"
              onClick={() => setShowFilter(!showFilter)}
            >
              <li className="mr-3 ml-auto font-bold">Filtrera</li>
            </button>
          </ul>
        </div>
      </nav>
    </header>
  );
}
