import { QueryStatus, useQuery } from "@tanstack/react-query";

import { Info } from "./CommonTypes";
import fetchInfo from "./fetchInfos";

export const useInfos = (company: string, site: string) => {
  const results = useQuery({
    queryKey: ["company", company, "site", site],
    queryFn: () => fetchInfo(company, site),
  });

  return [results?.data ?? [], results.status] as [Info[], QueryStatus];
};
